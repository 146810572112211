import Tempus from '@darkroom.engineering/tempus'
import { ref } from 'vue'

const errorThreshold = 0.009

function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end
}

function useReactiveLerp() {
    const lerpFrame = ref(-1)
    const lerpedProgress = ref(-1)

    function updateLerp(end, amt, cb) {
        lerpedProgress.value = lerp(lerpedProgress.value, end, amt)

        cb(lerpedProgress.value)

        // We stop the animation before reaching absolute value otherwise
        // it runs infinitely because of the nature of lerp calculation
        const diff = end - lerpedProgress.value
        if (Math.abs(diff) <= errorThreshold && lerpFrame.value !== -1) {
            lerpFrame.value()
            lerpFrame.value = -1
        }
    }

    function animateLerp(end, amt, cb) {
        if (lerpedProgress.value === -1) {
            lerpedProgress.value = end
        }

        updateLerp(end, amt, cb)

        const diff = end - lerpedProgress.value

        if (Math.abs(diff) > errorThreshold) {
            if (lerpFrame.value !== -1) {
                lerpFrame.value()
                lerpFrame.value = -1
            }
            lerpFrame.value = Tempus.add(() => {
                updateLerp(end, amt, cb)
            }, 0)
        }
    }

    return { animateLerp }
}

export { lerp, useReactiveLerp }
